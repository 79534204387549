import React, { FC } from 'react'
import { Button, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'


interface Props extends UploadProps{
  title: string
  acceptingFiles: string
  className?: string
  isLoading?: boolean
  onFileUpload: (file: RcFile) => void
}

export const UploadFiles: FC<Props> = (props) => {
  const {
    title,
    acceptingFiles,
    className,
    onFileUpload,
    fileList,
    isLoading,
    ...rest
  } = props
  const handleFileUpload = (file: RcFile) => {
    onFileUpload(file)

    return false
  }

  return (
    <Dragger
      maxCount={1}
      className={className}
      beforeUpload={handleFileUpload}
      accept={acceptingFiles}
      fileList={fileList}
      {...rest}
    >
      <Button
        loading={isLoading}
        icon={<UploadOutlined />}>
        { title }
      </Button>
    </Dragger>
  )
}
