import React, {
  useEffect,
  useState,
} from 'react'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import {
  createCarwash,
  fetchRentalObjectType,
} from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import {
  AREAS,
  DISTRICTS,
  SUBWAYS,
} from '../../config'
import { StatusRequest } from '../../../../../../stores/requestsReducer'
import { getRentalObjectDynamicFields } from '../../utils'
import {
  action,
  Actions,
} from '../../../../../../actions'
import { showSuccessNotification } from '../../../../../../utils/notification'
import { Group } from '../../Carwash/Group'
import { usePartnersSearch } from '../../../usePartnersSearch'
import {
  internalCitiesSelectOptions,
  rentUnitsSelectOptions,
} from '../../../../../../utils/enumUtils'

import styles from './styles.module.sass'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD-MM-YYYY'

const selector = (state: State) => {
  const { allPartners } = state.partnersReducer
  const {
    partnerIdForCreating,
    rentalObjectTypes,
    currentRentalObjectType,
  } = state.carwashesReducer

  const {
    fetchRentalObjectTypes,
    fetchRentalObjectType,
    createCarwash,
  } = state.requestsReducer

  return {
    partners: allPartners,
    partnerIdForCreating,
    rentalObjectTypes,
    currentRentalObjectType,
    isRentObjectsLoading: fetchRentalObjectTypes === StatusRequest.LOADING,
    isCurrentRentObjectLoading: fetchRentalObjectType  === StatusRequest.LOADING,
    createRequestStatus: createCarwash,
  }
}

export const CreateCard = (props: Props) => {
  const dispatch = useDispatch()
  const {
    partnerIdForCreating,
    rentalObjectTypes,
    currentRentalObjectType,
    isRentObjectsLoading,
    isCurrentRentObjectLoading,
    createRequestStatus,
  } = useSelector(selector)
  const [partnersQuery, setPartnersQuery] = useState<string>()
  const [isSearchedPartnersLoading, searchedPartners] = usePartnersSearch(partnersQuery)

  const {
    has_location,
    has_employees,
  } = currentRentalObjectType ?? {}

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const [ employees, setEmployees ] = useState<APITYPES.CarwashEmployeeBase[]>([])
  const [ prevRequestStatus, setPrevRequestStatus] = useState<StatusRequest>()

  const {
    isVisible,
    onCancel: onCancelExternal,
  } = props

  useEffect(() => {
    if (!isVisible) {
      return
    }
    const defaultObjectType = rentalObjectTypes?.[0]

    if (defaultObjectType) {
      form.setFieldValue(['object_type_id'], defaultObjectType.id)
      dispatch(fetchRentalObjectType(defaultObjectType.id))
    }
  }, [dispatch, isVisible])

  useEffect(() => {
    if (prevRequestStatus === StatusRequest.LOADING && createRequestStatus === StatusRequest.LOADED) {
      onClose()
      showSuccessNotification()
    }
    setPrevRequestStatus(createRequestStatus)
  }, [createRequestStatus])
  
  const onClose = () => {
    form.resetFields()
    dispatch(action(Actions.SET_IS_CARWASH_CREATING, false))
    dispatch(action(Actions.SET_CURRENT_RENTAL_OBJECT_TYPE, null))
    if (onCancelExternal) {
      onCancelExternal()
    }
  }

  const [ currentEmployee, setCurrentEmployee ] = useState<APITYPES.CarwashEmployeeBase>({
    name: '',
    role: '',
    phones: [],
    emails: [],
    comment: '',
  })

  const [form] = useForm()
  const ownType: 'Аренда' | 'Собственность' = Form.useWatch('own_type', form)

  const onAddEmployee = async () => {
    setEmployees((value) => ([...value, currentEmployee]))
  }

  const onFinish = async (values: any) => {
    dispatch(createCarwash({
      partner_id: values.partner_id,
      name: values.name,
      own_type: values.own_type,
      object_type_id: values.object_type_id,
      bq_id: parseFloat(values.bq_id),
      rent_cost: ownType === 'Аренда' ? values.rent_cost : null,
      rent_unit: ownType === 'Аренда' ? values.rent_unit : null,
      cooperation_date: values.cooperation_date ? values.cooperation_date.format(dateFormat) : undefined,
      cooperation_type: values.cooperation_type,
      location: has_location ? values.location : undefined,
      employees: has_employees
        ? employees
        : undefined,
      dynamic_data: values.dynamic_data,
    }))
  }

  const onChangeCurrentEmployee = (_: any, values: any) => {
    setCurrentEmployee({
      phones: values.phones ? values.phones.split(' ') : [],
      emails: values.emails ? values.emails.split(' ') : [],
      name: values.name ?? '',
      role: values.role ?? '',
      comment: values.comment ?? '',
    })
  }

  return (
    <Modal
      className={styles.modal}
      title='Создание '
      visible={isVisible}
      onCancel={onClose}
      footer={false}
    >
      <Form
        name='time_related_controls'
        form={form}
        {...formItemLayout}
        validateTrigger='onBlur'
        onFinish={onFinish}
      >
        <Group title='Общая информация'>
          <Form.Item
            name='name'
            label='Название объекта'
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='bq_id'
            label='id в прошлой системе'
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='partner_id'
            label='Партнер'
            initialValue={partnerIdForCreating}
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Select
              filterOption={false}
              notFoundContent={isSearchedPartnersLoading ? <Spin size='small' /> : null}
              allowClear
              showSearch
              onSearch={(query) => setPartnersQuery(query)}
            >
              {
                searchedPartners?.map((partner) => (
                  <Select.Option
                    key={partner.id}
                    value={partner.id}
                  >
                    { partner.legal_name }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name='own_type'
            label='Способ владения'
            initialValue='Аренда'
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Select showSearch>
              <Select.Option value='Аренда'>Аренда</Select.Option>
              <Select.Option value='Собственность'>Собственность</Select.Option>
            </Select>
          </Form.Item>
          {
            ownType === 'Аренда' && (
              <>
                <Form.Item
                  label='Стоимость аренды'
                  name='rent_cost'
                  rules={
                    [{
                      required: true,
                      message: 'Поле должно быть заполнено',
                    }]
                  }
                >
                  <InputNumber
                    precision={2}
                    type='number'
                  />
                </Form.Item>

                <Form.Item
                  label='Период оплаты'
                  name='rent_unit'
                  rules={
                    [{
                      required: true,
                      message: 'Поле должно быть заполнено',
                    }]
                  }
                >
                  <Select
                    defaultValue={undefined}
                    allowClear
                    options={rentUnitsSelectOptions}
                  />
                </Form.Item>
              </>
            )
          }
          <Form.Item
            label='Дата начала сотрудничества'
            name='cooperation_date'
          >
            <DatePicker format={viewDateFormat} />
          </Form.Item>
          <Form.Item
            name='cooperation_type'
            label='Формат сотрудничества'
            initialValue='Подряд'
          >
            <Select showSearch>
              <Select.Option value='Подряд'>Подряд</Select.Option>
              <Select.Option value='Аренда'>Аренда</Select.Option>
              <Select.Option value='Гибридный'>Гибридный</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='object_type_id'
            label='Тип объекта'
            rules={
              [{
                required: true,
                message: 'Поле должно быть заполнено',
              }]
            }
          >
            <Select
              loading={isRentObjectsLoading}
              showSearch
              onSelect={(id: string) => dispatch(fetchRentalObjectType(id))}
            >
              {
                rentalObjectTypes.map((type) => (
                  <Select.Option
                    key={type.id}
                    value={type.id}
                  >
                    { type.name }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Group>
        {
          isCurrentRentObjectLoading && (
            <Row justify='center'>
              <Spin size='large' />
            </Row>
          )
        }
        {
          (currentRentalObjectType && !isCurrentRentObjectLoading) && (
            <>
              <Group title='Характеристики обьекта'>
                { getRentalObjectDynamicFields(currentRentalObjectType, 'dynamic_data') }
              </Group>
              {
                has_location && (
                  <Group title='Местоположение'>
                    <Form.Item
                      name={['location', 'region']}
                      label='Регион'
                      initialValue='Москва'
                      rules={
                        [{
                          required: true,
                          message: 'Поле должно быть заполнено',
                        }]
                      }
                    >
                      <Select
                        options={internalCitiesSelectOptions}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'area']}
                      label='Округ'
                      initialValue={AREAS[0]}
                    >
                      <Select showSearch>
                        {
                          AREAS.map((area, i)=>{
                            return (
                              <Select.Option
                                key={i}
                                value={area}
                              >
                                { area }
                              </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['location', 'district']}
                      label='Район'
                      initialValue={DISTRICTS[0]}
                    >
                      <Select showSearch>
                        {
                          DISTRICTS.map((item, i)=>{
                            return (
                              <Select.Option
                                key={i}
                                value={item}
                              >
                                { item }
                              </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['location', 'address']}
                      label='Адрес'
                      rules={
                        [{
                          required: true,
                          message: 'Поле должно быть заполнено',
                        }]
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'subway']}
                      label='Метро'
                      initialValue={SUBWAYS[0]}
                    >
                      <Select showSearch>
                        {
                          SUBWAYS.map((item, i)=>{
                            return (
                              <Select.Option
                                key={i}
                                value={item}
                              >
                                { item }
                              </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['location', 'directions']}
                      label='Как проехать'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'longitude']}
                      label='Долгота'
                      rules={
                        [{
                          required: true,
                          message: 'Поле должно быть заполнено',
                        }]
                      }
                    >
                      <Input
                        type='number'
                        step={0.000001}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['location', 'latitude']}
                      label='Широта'
                      rules={
                        [{
                          required: true,
                          message: 'Поле должно быть заполнено',
                        }]
                      }
                    >
                      <Input
                        type='number'
                        step={0.000001}
                      />
                    </Form.Item>
                  </Group>
                )
              }
              {
                has_employees && (
                  <Group title='Сотрудники'>
                    <Form
                      name='employeesForm'
                      onValuesChange={onChangeCurrentEmployee}
                    >
                      <Form.Item
                        name='name'
                        label='ФИО'
                        rules={
                          [{
                            required: true,
                            message: 'Поле должно быть заполнено',
                          }]
                        }
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name='role'
                        label='Должность'
                        rules={
                          [{
                            required: true,
                            message: 'Поле должно быть заполнено',
                          }]
                        }
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name='phones'
                        label='Телефоны (пишите через пробел)'
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name='emails'
                        label='Почты (пишите через пробел)'
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name='comment'
                        label='Комментарий'
                      >
                        <Input />
                      </Form.Item>
                      <Button
                        type='primary'
                        onClick={onAddEmployee}
                      >
                        Добавить работника
                      </Button>
                      {
                        employees.map((item, i) => {
                          return (
                            <Card
                              key={i}
                              size='small'
                              title={item.name}
                              style={
                                {
                                  width: 300,
                                  marginTop: 20,
                                }
                              }
                            >
                              <p>{ item.role }</p>
                            </Card>
                          )
                        })
                      }
                    </Form>
                  </Group>
                )
              }
            </>
          )
        }
        <FormFooter
          className={styles.footer}
          onCancel={onClose}
          isLoading={createRequestStatus === StatusRequest.LOADING}
          position='right'
        />
      </Form>
    </Modal>
  )
}