import qs from 'qs'
import moment from 'moment'


export type M = moment.Moment
export const backDateFormat = 'YYYY-MM-DD'

type Query = {
  begin: string
  end: string
  object_type_id: string
  rent_unit: string
}

export const getPickerStateFromSearch = (queryStr: string) => {
  let query = queryStr

  if (queryStr) {
    query = queryStr.replace('?', '')
  } else {
    return undefined
  }

  const queryObj = qs.parse(query) as Query

  if (queryObj.begin && queryObj.end) {
    return [moment(queryObj.begin), moment(queryObj.end)]
  }

  return undefined
}

export const getObjectTypeStateFromSearch = (queryStr: string) => {
  const query = queryStr.replace('?', '')
  const queryObj = qs.parse(query) as Query

  return queryObj.object_type_id
}

export const getRentUnitStateFromSearch = (queryStr: string) => {
  const query = queryStr.replace('?', '')
  const queryObj = qs.parse(query) as Query

  return queryObj.rent_unit
}

export const getFormattedDates = (dates: [M, M]) => {
  const [begin, end] = dates

  return {
    begin: begin.format(backDateFormat),
    end: end.format(backDateFormat),
  }
}