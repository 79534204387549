import React, {
  useEffect,
  useState,
} from 'react'
import {
  Button,
  DatePicker,
  Select,
} from 'antd'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ROUTES } from 'config/routes'
import {
  fetchRentByPeriod,
  sendRentsToBilling,
  sendUtilityAccrualsToBilling,
} from 'AC/rent/rent'
import {
  action,
  Actions,
} from '../../../../../actions'
import { APITYPES } from '../../../../../types'
import { openNotification } from '../../../../../components/modals/OperationNotification'
import { rentUnitsSelectOptions } from '../../../../../utils/enumUtils'

import {
  getFormattedDates,
  getObjectTypeStateFromSearch,
  getPickerStateFromSearch,
  getRentUnitStateFromSearch,
  M,
} from './utils'
import styles from './styles.module.sass'

import RentalObjectTypeItem = APITYPES.RentalObjectTypeItem


type Props = {
  hasBillingData: boolean
  objectTypes: RentalObjectTypeItem[]
  onChangeDataType: (value: boolean) => void
  isUtilityTypeSelected : boolean
}

export const RentHeader = ({ hasBillingData, objectTypes, onChangeDataType, isUtilityTypeSelected }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [dateRange, setDateRange] = useState<[M, M] | undefined>(getPickerStateFromSearch(location.search) as [M, M] | undefined)
  const [objectTypeId, setObjectTypeId] = useState<string | undefined>(getObjectTypeStateFromSearch(location.search))
  const [rentUnit, setRentUnit] = useState<string | undefined>(getRentUnitStateFromSearch(location.search))
  const selectedDomain = objectTypes.find((type) => type.id === objectTypeId)?.domain

  const {
    isLoading: isUtilityAccrualsBilling,
    mutate: billUtilityAccruals,
  } = useMutation(sendUtilityAccrualsToBilling, {
    // TODO: KZ: update object-curly-newline for long lines
    onSuccess: () => openNotification('Выполнено'),
  })

  const onPickerChange = (dates: [M, M] | null) => {
    if (!dates) {
      history.push(ROUTES.PARTNERS.RENT.path)
      dispatch(action(Actions.SET_RENT_DATA, null))
      
      return
    }
    const dateParams = getFormattedDates(dates)

    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath({
      ...dateParams,
      rent_unit: rentUnit,
      object_type_id: objectTypeId,
    }))
  }

  const onObjectTypeChange = (id: string) => {
    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath({
      object_type_id: id,
      rent_unit: rentUnit,
      ...dateRange ? getFormattedDates(dateRange) : {},
    }))
  }

  const onRentUnitChange = (unit: string) => {
    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath({
      object_type_id: objectTypeId,
      rent_unit: unit,
      ...dateRange ? getFormattedDates(dateRange) : {},
    }))
  }

  useEffect(() => {
    setDateRange(getPickerStateFromSearch(location.search) as [M, M] | undefined)
    setObjectTypeId(getObjectTypeStateFromSearch(location.search))
    setRentUnit(getRentUnitStateFromSearch(location.search))
  }, [location.search])


  const onUpdate = (isUtilityType = false) => {
    if (!dateRange) { return }
    const dates = getFormattedDates(dateRange)

    onChangeDataType(isUtilityType)
    if (!isUtilityType) {
      dispatch(fetchRentByPeriod({
        ...dates,
        object_type_id: objectTypeId,
        rent_unit: rentUnit,
      }))
    }
  }

  const onSendToBilling = () => {
    if (!dateRange) { return }
    const dates = getFormattedDates(dateRange)

    if (isUtilityTypeSelected) {
      billUtilityAccruals({
        begin_date: dates.begin,
        end_date: dates.end,
        object_type_id: objectTypeId,
      })
    } else {
      dispatch(sendRentsToBilling({
        ...dates,
        rent_unit: rentUnit,
        object_type_id: objectTypeId,
      }))
    }
  }

  return (
    <div className={styles.container}>
      <DatePicker.RangePicker
        value={dateRange}
        onChange={
          (values) =>
            // @ts-ignore
            onPickerChange(values)
        }
        format='L'
      />
      <Select
        allowClear
        showSearch
        placeholder='Тип объекта'
        value={objectTypeId}
        onChange={onObjectTypeChange}
        filterOption={
          (input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          objectTypes.map((type) => (
            <Select.Option
              key={type.id}
              value={type.id}
            >
              { type.name }
            </Select.Option>
          ))
        }
      </Select>
      <Select
        allowClear
        showSearch
        placeholder='Период расчета'
        value={rentUnit}
        onChange={onRentUnitChange}
        options={rentUnitsSelectOptions}
        filterOption={
          (input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Button
        disabled={!dateRange}
        onClick={() => onUpdate()}
      >
        Рассчитать аренду
      </Button>
      {
        selectedDomain === 'space' && (
          <Button
            disabled={!dateRange}
            onClick={() => onUpdate(true)}
          >
            Рассчитать комм. платежи
          </Button>
        )
      }
      <Button
        loading={isUtilityAccrualsBilling}
        disabled={!hasBillingData}
        onClick={onSendToBilling}
      >
        Закрыть период
      </Button>
    </div>
  )
}
