import React, { FC } from 'react'
import { Modal } from 'antd'
import { useMutation } from '@tanstack/react-query'

import { UploadFiles } from 'shared/ui/UploadFiles'
import { openNotification } from '../../../../../../components/modals/OperationNotification'
import { uploadYandexEdaReportFile } from '../../../../../../AC/yandexEda/reports'

import styles from './styles.module.sass'


interface Props {
  isOpen: boolean
  onClose: () => void
}

export const UploadReportFileModal: FC<Props> = ({ isOpen, onClose }) => {
  const {
    isLoading: isReportUploading,
    mutate: uploadReport,
  } = useMutation(
    uploadYandexEdaReportFile,
    {
      onSuccess: () => {
        openNotification('Отчет успешно загружен')
        onClose()
      },
    }
  )

  return (
    <Modal
      title='Загрузка отчета Яндекс Лавка'
      open={isOpen}
      onCancel={onClose}
      destroyOnClose
      width={1200}
      footer={false}
    >
      <div className={styles.wrapper}>
        <section className={styles.uploading}>
          <UploadFiles
            isLoading={isReportUploading}
            title='Загрузите файл'
            acceptingFiles='.csv'
            fileList={[]}
            onFileUpload={(file) => uploadReport(file)}
          />
        </section>
      </div>
    </Modal>

  )
}
