import moment from 'moment/moment'

import { dateFormat, dateTimeFormat } from 'utils/dateUtils'
import { APITYPES } from 'types/apitypes'

import { ShiftReportsType } from './index'


export const getReportTableCols = (type: ShiftReportsType) => ([
  (type === 'urent' ?
    {
      title: 'Дата отчета',
      dataIndex: 'report_date',
      key: 'report_date',
      render: (date: string) => moment(date).format(dateFormat),
    } : {
      title: 'Период отчета',
      dataIndex: 'report_date',
      key: 'report_date',
      render: (date: string, item: APITYPES.Urent.Report) =>
        `${moment(item.date_from).format(dateFormat)} - ${moment(item.date_to).format(dateFormat)}`,
    }
  ),
  {
    title: 'Загружен',
    dataIndex: 'created',
    key: 'created',
    render: (date: string) => moment(date).format(dateTimeFormat),
  },
  {
    title: 'Обработан',
    dataIndex: 'deployed',
    key: 'deployed',
    render: (date: string) => date ?  moment(date).format(dateTimeFormat) : '',
  },
])


export type MappedReport = APITYPES.Urent.Report & {
  key: string
}

export const mapReportsToTable = (reports: APITYPES.Urent.Report[]): MappedReport[] => {
  return reports.map((report) => {
    return {
      key: report.id,
      ...report,
    }
  })
}