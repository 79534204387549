import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Button, Row, Space, Table } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { action, Actions } from 'actions'
import { getDoers } from 'AC/doers/getDoers'
import { fetchRoles } from 'AC/doers/doerRoles'
import { Loader } from 'components/Loader'
import { isObjectEmpty } from 'utils/filterUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { getTableYScroll } from 'utils/scrollUtils'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { ROUTES } from 'config/routes'
import { FilterIcon } from 'components/FilterIcon'
import { AddDoerModal } from './Doer/AddDoerModal'

import FiltersDoers from './Filters'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const selector = (state: State) => ({
  doers: state.doersReducer.doers,
  doersFilter: state.doersReducer.doersFilter,
  roles: state.doersReducer.roles,
  hasMore: state.doersReducer.hasMore,
  isDoersLoading:
    state.requestsReducer.getDoers === StatusRequest.LOADING ||
    state.requestsReducer.fetchRolesOfEachDoer === StatusRequest.LOADING,
})

export const Doers = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { doers, doersFilter, roles, isDoersLoading, hasMore } =
    useSelector(selector)
  const params = useMemo(() => getUrlSearchParams(), [])
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  /** filter state */
  const [visible, setVisible] = useState(false)
  const toggleFilter = () => setVisible((prev) => !prev)

  /** create doer state */
  const [openAddDoerModal, setOpenAddDoerModal] = useState(false)
  const toggleOpenAddDoerModal = () => setOpenAddDoerModal(!openAddDoerModal)


  // on init
  useEffect(() => {
    dispatch(action(Actions.CLEAN_DOERS, {}))
    if (!roles) {
      dispatch(fetchRoles())
    }

    if (!isObjectEmpty(params)) {
      dispatch(
        action(Actions.SET_DOERS_FILTER, {
          ...params,
          role_id: params?.role_id ? params?.role_id.split(',') : undefined,
        })
      )
    }
  }, [dispatch, params, roles])

  // load more when intersecting last table row
  useEffect(() => {
    if (!isDoersLoading && hasMore && entry?.isIntersecting) {
      dispatch(getDoers(doersFilter))
    }
  }, [dispatch, doersFilter, entry?.isIntersecting, hasMore, isDoersLoading])

  if (!doers || !roles) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Row>
          <Button
            type='primary'
            onClick={toggleOpenAddDoerModal}
          >
            Добавить исполнителя
          </Button>
        </Row>
        <Row>
          <Space size={8}>
            <Button
              size='small'
              onClick={toggleFilter}
              icon={<FilterIcon isFilterEmpty={isObjectEmpty(doersFilter)} />}
            />
          </Space>
        </Row>
      </div>

      <Table
        sticky
        loading={isDoersLoading}
        size='small'
        columns={getColumns(doersFilter) as any}
        className={styles.table}
        dataSource={getRows(doers || [])}
        bordered
        pagination={false}
        onRow={
          (record, index) => {
            return {
              ref: index === doers?.length - 1 ? lastRowRef : undefined,
              onClick: () =>
                history.push(ROUTES.DOERS.DOERS.createPath(record.key)),
            }
          }
        }
        scroll={{ y: getTableYScroll(164) }}
      />

      <AddDoerModal
        isOpen={openAddDoerModal}
        defaultState='confirmed'
        onClose={toggleOpenAddDoerModal}
        onSuccess={toggleOpenAddDoerModal}
      />
      <FiltersDoers
        isOpen={visible}
        onClose={toggleFilter}
      />
    </div>
  )
}
