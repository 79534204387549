import React, { useState } from 'react'
import { Button } from 'antd'

import styles from '../YandexProReports/styles.module.sass'
import { UploadReportFileModal } from './components/UploadReportFileModal/UploadReportFileModal'


export const YandexEda = () => {
  const [isUploadReportFileModalOpen, setIsUploadReportFileModalOpen] = useState(false)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={() => setIsUploadReportFileModalOpen(true)}
          >
            Загрузить отчет
          </Button>
        </div>
      </div>

      <UploadReportFileModal
        isOpen={isUploadReportFileModalOpen}
        onClose={() => setIsUploadReportFileModalOpen(false)}
      />
    </>
  )
}
