// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module--wrapper--ci5OV{height:250px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:3rem;margin-top:1rem}.styles-module--wrapper--ci5OV .styles-module--uploading--r7lA5{height:200px}", "",{"version":3,"sources":["webpack://./src/App/PrivateRoutes/Doers/YandexEda/components/UploadReportFileModal/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,QAAA,CACA,eAAA,CACA,gEACE,YAAA","sourcesContent":[".wrapper\n  height: 250px\n  display: flex\n  flex-direction: column\n  gap: 3rem\n  margin-top: 1rem\n  .uploading\n    height: 200px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles-module--wrapper--ci5OV",
	"uploading": "styles-module--uploading--r7lA5"
};
export default ___CSS_LOADER_EXPORT___;
