import { RcFile } from 'antd/es/upload'

import { showError } from 'components/modals/ErrorModal'
import { apiV1 } from 'network/http'


export const uploadYandexEdaReportFile = async (file: RcFile) => {
  const formData = new FormData()

  formData.append('file',file)
  try {
    return await apiV1.post(
      'v1/yandex_taxi/reports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
        },
      }
    ) as void
  } catch (e: any) {
    showError(e.response.data.description)
    throw(e)
  }
}
