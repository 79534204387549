import React, { useMemo, useState } from 'react'
import { Button, Input, Space } from 'antd'
import cn from 'classnames'

import { APITYPES } from 'types/apitypes'
import styles from '../../styles.module.sass'
import { putReportElement } from '../../api'
import { SelectReport } from '../SelectReport'


type ReportProps = {
  report: APITYPES.OperationReport
}

export const Report = ({ report }: ReportProps) => {
  const [inputValue, setInputValue] = useState(report?.values[0])
  const [error, setError] = useState<'' | 'error' | 'warning' | undefined>('')
  
  const mode = useMemo(() => report.type === 'selector' ? 'multiple' : undefined, [report.type])


  const handleSelectChange = async (value: string[]) => {
    const values = Array.isArray(value) ? value : [value]
    const { id, task_element_id } = report

    if (id !== undefined) {
      await putReportElement(id, {
        values,
        task_element_id,
      })
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setError('')
  }

  const handleInputSubmit = async () => {
    const {
      id,
      task_element_id,
    } = report

    if (id === undefined) {
      return
    }

    if (!report?.validator) {
      await putReportElement(id,{
        values: [inputValue],
        task_element_id,
      })

      return
    }

    const regex = new RegExp(report.validator)

    if (regex.test(inputValue)) {
      await putReportElement(id,{
        values: [inputValue],
        task_element_id,
      })
    } else {
      setError('error')
    }
  }

  return (
    <div
      className={styles.textReportsWrapper}
    >
      <div className={styles.left}>
        <span>
          { report?.task_element_name ?? report?.name }
          :
        </span>
      </div>

      <div className={cn(styles.right, styles.textReports)}>
        {
          report.options !== null && report.options !== undefined && report.options.length > 0 ? (
            <SelectReport
              mode={mode}
              options={report.options}
              defaultValue={report.values}
              handleChange={handleSelectChange}
            />
          ) : (
            <Space direction='horizontal'>
              <Input
                value={inputValue}
                status={error}
                onChange={handleInputChange}
              />
              <Button
                type='primary'
                onClick={handleInputSubmit}
              >
                Сохранить
              </Button>
            </Space>
          )
        }
      </div>
    </div>
  )
}
