/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios'

import { APITYPES } from 'types/apitypes'


export type ReplaceUrl = { [key: string]: string | number };

export type Api = {
  setAuth(header: string): void

  get(
    url: typeof APITYPES.Billing.GetBillById.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.GetBillById.Resp>
  get(
    url: typeof APITYPES.Billing.GetContractors.URL,
    options: { params: APITYPES.Billing.GetContractors.Req }
  ): Promise<APITYPES.Billing.GetContractors.Resp>
  get(
    url: typeof APITYPES.Billing.GetContractorRecords.URL,
    options: {
      replaceUrl: { id: string }
      params: APITYPES.Billing.GetContractorRecords.Req
    }
  ): Promise<APITYPES.Billing.GetContractorRecords.Resp>
  get(
    url: typeof APITYPES.Billing.GetBills.URL,
    options: { params: APITYPES.Billing.GetBills.Req }
  ): Promise<APITYPES.Billing.GetBills.Resp>
  post(
    url: typeof APITYPES.Billing.GetExportBills.URL,
    data: null,
    options: {
      params: APITYPES.Billing.GetExportBills.Query
    }
  ): Promise<APITYPES.Billing.GetExportBills.Resp>
  post(
    url: typeof APITYPES.Billing.GetExportBillsPnl.URL,
    data: null,
    options: {
      params: APITYPES.Billing.GetExportBillsConsole.Query
    }
  ): Promise<APITYPES.Billing.GetExportBillsConsole.Resp>

  get(
    url: typeof APITYPES.Billing.GetDoerRecords.URL,
    options: {
      params: APITYPES.Billing.GetDoerRecords.Req
    }
  ): Promise<APITYPES.Billing.GetDoerRecords.Resp>

  get(
    url: typeof APITYPES.Billing.GetDoerRecordTypes.URL,
  ): Promise<APITYPES.Billing.GetDoerRecordTypes.Resp>

  get(
    url: typeof APITYPES.Billing.GetDoerRecordsPeriod.URL,
    options: {
      params: APITYPES.Billing.GetDoerRecordsPeriod.Params
    }
  ): Promise<APITYPES.Billing.GetDoerRecordsPeriod.Resp>

  get(
    url: typeof APITYPES.Billing.ExportDoerRecords.URL,
    options: {
      params: APITYPES.Billing.ExportDoerRecords.Req
      headers: any
      responseType: 'blob'
    }
  ): Promise<APITYPES.Billing.ExportDoerRecords.Resp>

  delete(
    url: typeof APITYPES.Billing.DeleteDoerRecord.URL,
    options: { replaceUrl: { doer_record_id: string } }
  ): Promise<APITYPES.Billing.DeleteDoerRecord.Resp>

  post(
    url: typeof APITYPES.Billing.AcceptDoerRecords.URL,
    body: APITYPES.Billing.AcceptDoerRecords.Req,
  ): Promise<APITYPES.Billing.AcceptDoerRecords.Resp>

  post(
    url: typeof APITYPES.Billing.ImportDoerRecords.URL,
    body: APITYPES.Billing.ImportDoerRecords.Req,
    options: any,
  ): Promise<APITYPES.Billing.ImportDoerRecords.Resp>

  post(
    url: typeof APITYPES.Billing.PayDoerRecords.URL,
    body: null,
    options: {
      params: APITYPES.Billing.PayDoerRecords.Req
    }
  ): Promise<APITYPES.Billing.PayDoerRecords.Resp>

  post(
    url: typeof APITYPES.Billing.GetExportBillsConsole.URL,
    data: null,
    options: {
      params: APITYPES.Billing.GetExportBillsPnl.Query
    }
  ): Promise<APITYPES.Billing.GetExportBillsPnl.Resp>
  post(
    url: typeof APITYPES.Billing.PostImportBillsItems.URL,
    data: APITYPES.Billing.PostImportBillsItems.Req
  ): Promise<APITYPES.Billing.PostImportBillsItems.Resp>
  post(
    url: typeof APITYPES.Billing.PostInvoicesForPayment.URL,
    data: null,
    options: {
      params: APITYPES.Billing.PostInvoicesForPayment.Req
    }
  ): Promise<APITYPES.Billing.PostInvoicesForPayment.Resp>

  get(
    url: typeof APITYPES.Billing.CreateBillDownloadUrl.URL,
    options: { replaceUrl: { bill_id: string } }
  ): Promise<APITYPES.Billing.CreateBillDownloadUrl.Resp>
  get(
    url: typeof APITYPES.Documents.Partners.Get.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Documents.Partners.Get.Resp>
  get(
    url: typeof APITYPES.Documents.Partners.GetDocument.URL,
    options: { replaceUrl: { partner_id: string, document_id: string } }
  ): Promise<APITYPES.Documents.Partners.GetDocument.Resp>
  get(
    url: typeof APITYPES.Partners.Get.URL,
    options: { params: APITYPES.Partners.Get.Req }
  ): Promise<APITYPES.Partners.Get.Resp>
  get(
    url: typeof APITYPES.Carwashes.Get.URL,
    options: { params: APITYPES.Carwashes.Get.Req }
  ): Promise<APITYPES.Carwashes.Get.Resp>
  get(
    url: typeof APITYPES.RentalObjectTypes.Get.URL,
    options: { params: APITYPES.RentalObjectTypes.Get.Req }
  ): Promise<APITYPES.RentalObjectTypes.Get.Resp>
  get(
    url: typeof APITYPES.RentalObjectTypes.GetRentalObjectType.URL,
    options: { replaceUrl: APITYPES.RentalObjectTypes.GetRentalObjectType.Req},
  ): Promise<APITYPES.RentalObjectTypes.GetRentalObjectType.Resp>
  get(
    url: typeof APITYPES.Dadata.Party.URL,
    options: { params: APITYPES.Dadata.Party.Req }
  ): Promise<APITYPES.Dadata.Party.Resp>
  get(
    url: typeof APITYPES.Partners.GetPartner.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Partners.GetPartner.Resp>
  get(
    url: typeof APITYPES.Carwashes.GetCarwash.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.GetCarwash.Resp>
  get(
    url: typeof APITYPES.Office.Tariff.Get.URL,
    options: { params: APITYPES.Office.Tariff.Get.Req }
  ): Promise<APITYPES.Office.Tariff.Get.Resp>
  get(
    url: typeof APITYPES.ClientTypes.Get.URL
  ): Promise<APITYPES.ClientTypes.Get.Resp>
  get(
    url: typeof APITYPES.CarClasses.Get.URL,
    options: { replaceUrl: APITYPES.CarClasses.Get.Req }
  ): Promise<APITYPES.CarClasses.Get.Resp>
  get(
    url: typeof APITYPES.Services.Get.URL,
    options: { replaceUrl: APITYPES.Services.Get.Req }
  ): Promise<APITYPES.Services.Get.Resp>
  get(
    url: typeof APITYPES.AllServices.Get.URL
  ): Promise<APITYPES.AllServices.Get.Resp>
  get(
    url: typeof APITYPES.AllCarClasses.Get.URL
  ): Promise<APITYPES.AllCarClasses.Get.Resp>
  get(
    url: typeof APITYPES.Cars.GetBrands.URL
  ): Promise<APITYPES.Cars.GetBrands.Resp>
  get(
    url: typeof APITYPES.Cars.GetModels.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Cars.GetModels.Resp>
  get(
    url: typeof APITYPES.Cars.GetModelInfo.URL,
    options: { replaceUrl: { car_brand_id: string, car_model_id: string } }
  ): Promise<APITYPES.Cars.GetModelInfo.Resp>
  get(
    url: typeof APITYPES.AllCarClasses.GetCars.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.AllCarClasses.GetCars.Resp>
  get(
    url: typeof APITYPES.Carwashes.GetPhotos.URL,
    options: { replaceUrl: APITYPES.Carwashes.GetPhotos.Req }
  ): Promise<APITYPES.Carwashes.GetPhotos.Resp>
  get(
    url: typeof APITYPES.Statistics.Carwashes.URL
  ): Promise<APITYPES.Statistics.Carwashes.Resp>
  get(
    url: typeof APITYPES.Statistics.Partners.URL
  ): Promise<APITYPES.Statistics.Partners.Resp>
  get(
    url: typeof APITYPES.ClientTypes.GetClientType.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.ClientTypes.GetClientType.Resp>
  get(
    url: typeof APITYPES.AllServices.GetService.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.AllServices.GetService.Resp>

  get(
    url: typeof APITYPES.CarwashClientTypes.Get.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.CarwashClientTypes.Get.Resp>

  post(
    url: typeof APITYPES.Billing.CreateBill.URL,
    data: APITYPES.Billing.CreateBill.Req
  ): Promise<APITYPES.Billing.CreateBill.Resp>
  post(
    url: typeof APITYPES.Billing.AddBillItem.URL,
    data: APITYPES.Billing.AddBillItem.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.AddBillItem.Resp>
  post(
    url: typeof APITYPES.Billing.CancelBillById.URL,
    data: APITYPES.Billing.CancelBillById.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.CancelBillById.Resp>
  // post(url: typeof APITYPES.Billing.AcceptBillById.URL, data: APITYPES.Billing.AcceptBillById.Req, options: { replaceUrl: { id: string }}): Promise<APITYPES.Billing.AcceptBillById.Resp>
  // post(url: typeof APITYPES.Billing.DeclineBillById.URL, data: APITYPES.Billing.DeclineBillById.Req, options: { replaceUrl: { id: string }}): Promise<APITYPES.Billing.DeclineBillById.Resp>
  post(
    url: typeof APITYPES.Billing.NegotiationBillById.URL,
    data: APITYPES.Billing.NegotiationBillById.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.NegotiationBillById.Resp>
  post(
    url: typeof APITYPES.Billing.PaidBillById.URL,
    data: APITYPES.Billing.PaidBillById.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.PaidBillById.Resp>
  post(
    url: typeof APITYPES.Billing.PayingBillById.URL,
    data: APITYPES.Billing.PayingBillById.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.PayingBillById.Resp>
  post(
    url: typeof APITYPES.Billing.CreateBillDownloadUrl.URL,
    data: {},
    options: { replaceUrl: { bill_id: string } }
  ): Promise<APITYPES.Billing.CreateBillDownloadUrl.Resp>
  post(
    url: typeof APITYPES.Documents.Partners.Post.URL,
    data: APITYPES.Documents.Partners.Post.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Documents.Partners.Post.Resp>
  post(
    url: typeof APITYPES.Partners.Post.URL,
    data: APITYPES.Partners.Post.Req
  ): Promise<APITYPES.Partners.Post.Resp>
  post(
    url: typeof APITYPES.Partners.Post.URL,
    data: APITYPES.Partners.Post.Req
  ): Promise<APITYPES.AllCarClasses.Post.Resp>
  post(
    url: typeof APITYPES.Carwashes.Post.URL,
    data: APITYPES.Carwashes.Post.Req
  ): Promise<APITYPES.Carwashes.Post.Resp>
  post(
    url: typeof APITYPES.AllCarClasses.Post.URL,
    data: APITYPES.AllCarClasses.Post.Req
  ): Promise<APITYPES.AllCarClasses.Post.Resp>
  post(
    url: typeof APITYPES.Auth.URL,
    data: APITYPES.Auth.Req
  ): Promise<APITYPES.Auth.Resp>
  post(
    url: typeof APITYPES.Office.Tariff.Post.URL,
    data: APITYPES.Office.Tariff.Post.Req
  ): Promise<APITYPES.Office.Tariff.Post.Resp>
  post(
    url: typeof APITYPES.Employees.Post.URL,
    data: APITYPES.Employees.Post.Req,
    options: { replaceUrl: { carwash_id: string } }
  ): Promise<APITYPES.Employees.Post.Resp>
  post(
    url: typeof APITYPES.AllServices.Post.URL,
    data: APITYPES.AllServices.Post.Req
  ): Promise<APITYPES.AllServices.Post.Resp>
  post(
    url: typeof APITYPES.ClientTypes.Post.URL,
    data: APITYPES.ClientTypes.Post.Req
  ): Promise<APITYPES.ClientTypes.Post.Resp>
  post(
    url: typeof APITYPES.CarClasses.Post.URL,
    data: APITYPES.CarClasses.Post.Req,
    options: { replaceUrl: { client_type_id: string } }
  ): Promise<APITYPES.CarClasses.Post.Resp>
  post(
    url: typeof APITYPES.Services.Post.URL,
    data: APITYPES.Services.Post.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Services.Post.Resp>
  post(
    url: typeof APITYPES.Carwashes.AddPhoto.URL,
    data: APITYPES.Carwashes.AddPhoto.Req,
    options: { replaceUrl: { carwash_id: string } }
  ): Promise<APITYPES.Carwashes.AddPhoto.Resp>
  post(
    url: typeof APITYPES.Partners.PartnersSync.URL,
    data: APITYPES.Partners.PartnersSync.Req
  ): Promise<APITYPES.Partners.PartnersSync.Resp>
  post(
    url: typeof APITYPES.Partners.PartnersExport.URL,
    body: {},
    options:{
      params: APITYPES.Partners.PartnersExport.Query
    }
  ): Promise<APITYPES.Partners.PartnersExport.Resp>
  post(
    url: typeof APITYPES.Partners.CarwashesExport.URL,
    body: {},
    options:{
      params: APITYPES.CarwashFilter
    }
  ): Promise<APITYPES.Partners.CarwashesExport.Resp>
  post(
    url: typeof APITYPES.CopyTariff.URL,
    data: APITYPES.CopyTariff.Req
  ): Promise<APITYPES.CopyTariff.Resp>
  post(
    url: typeof APITYPES.Statistics.Operations.POST.URL,
    body: APITYPES.Statistics.Operations.POST.Req
  ): Promise<APITYPES.Statistics.Operations.POST.Resp>

  put(
    url: typeof APITYPES.Documents.Partners.UpdateDocument.URL,
    data: APITYPES.Documents.Partners.UpdateDocument.Req,
    options: { replaceUrl: { partner_id: string, document_id: string } }
  ): Promise<APITYPES.Documents.Partners.UpdateDocument.Resp>
  put(
    url: typeof APITYPES.Partners.Put.URL,
    data: APITYPES.Partners.Put.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Partners.Put.Resp>
  put(
    url: typeof APITYPES.Partners.Off.URL,
    data: APITYPES.Partners.Off.Req,
    options: { replaceUrl: { partner_id: string } }
  ): Promise<APITYPES.Partners.Off.Resp>
  put(
    url: typeof APITYPES.Partners.Activate.URL,
    data: APITYPES.Partners.Activate.Req,
    options: { replaceUrl: { partner_id: string } }
  ): Promise<APITYPES.Partners.Activate.Resp>
  put(
    url: typeof APITYPES.Carwashes.Put.URL,
    data: APITYPES.Carwashes.Put.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.Put.Resp>
  put(
    url: typeof APITYPES.Carwashes.Off.URL,
    data: APITYPES.Carwashes.Off.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.Off.Resp>
  put(
    url: typeof APITYPES.Carwashes.Deactivate.URL,
    data: APITYPES.Carwashes.Deactivate.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.Deactivate.Resp>
  put(
    url: typeof APITYPES.Carwashes.Activate.URL,
    data: {},
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.Activate.Resp>
  put(
    url: typeof APITYPES.Office.Tariff.Put.URL,
    data: APITYPES.Office.Tariff.Put.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Office.Tariff.Put.Resp>
  put(
    url: typeof APITYPES.Employees.Put.URL,
    data: APITYPES.Employees.Put.Req,
    options: { replaceUrl: { carwash_id: string, employee_id: string } }
  ): Promise<APITYPES.Employees.Put.Resp>
  put(
    url: typeof APITYPES.AllCarClasses.AddCar.URL,
    data: {},
    options: { replaceUrl: { car_class_id: string, car_model_id: string } }
  ): Promise<APITYPES.AllCarClasses.AddCar.Resp>
  put(
    url: typeof APITYPES.CarClasses.Put.URL,
    data: APITYPES.CarClasses.Put.Req,
    options: { replaceUrl: { client_type_id: string, car_class_id: string } }
  ): Promise<APITYPES.CarClasses.Put.Resp>
  put(
    url: typeof APITYPES.Services.Put.URL,
    data: APITYPES.Services.Put.Req,
    options: { replaceUrl: { client_type_id: string, service_id: string } }
  ): Promise<APITYPES.Services.Put.Resp>
  put(
    url: typeof APITYPES.CarwashClientTypes.Disconnect.URL,
    data: {},
    options: { replaceUrl: { carwash_id: string, client_type_id: string } }
  ): Promise<APITYPES.CarwashClientTypes.Disconnect.Resp>
  put(
    url: typeof APITYPES.CarwashClientTypes.Connect.URL,
    data: {},
    options: { replaceUrl: { carwash_id: string, client_type_id: string } }
  ): Promise<APITYPES.CarwashClientTypes.Connect.Resp>

  put(url: string, data: any): Promise<any>
  put(
    url: typeof APITYPES.AllServices.Put.URL,
    data: APITYPES.AllServices.Put.Req,
    options: { replaceUrl: { service_id: string } }
  ): Promise<APITYPES.AllServices.Put.Resp>
  put(
    url: typeof APITYPES.ClientTypes.Put.URL,
    data: APITYPES.ClientTypes.Put.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.ClientTypes.Put.Resp>
  put(
    url: typeof APITYPES.Carwashes.ReplacePartner.URL,
    data: APITYPES.Carwashes.ReplacePartner.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Carwashes.ReplacePartner.Resp>
  put(
    url: typeof APITYPES.Billing.UpdateBillById.URL,
    data: APITYPES.Billing.UpdateBillById.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Billing.UpdateBillById.Resp>
  put(
    url: typeof APITYPES.Billing.UpdateBillItem.URL,
    data: APITYPES.Billing.UpdateBillItem.Req,
    options: { replaceUrl: { bill_id: string, bill_item_id: string } }
  ): Promise<APITYPES.Billing.UpdateBillItem.Resp>

  delete(
    url: typeof APITYPES.Billing.DeleteBillItem.URL,
    options: { replaceUrl: { bill_id: string, bill_item_id: string } }
  ): Promise<APITYPES.Billing.DeleteBillItem.Resp>
  delete(
    url: typeof APITYPES.Documents.Partners.DeleteDocument.URL,
    options: { replaceUrl: { partner_id: string, document_id: string } }
  ): Promise<APITYPES.Documents.Partners.DeleteDocument.Resp>
  delete(
    url: typeof APITYPES.Office.Tariff.Delete.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Office.Tariff.Delete.Resp>
  delete(
    url: typeof APITYPES.AllServices.Delete.URL,
    options: { replaceUrl: { service_id: string } }
  ): Promise<APITYPES.AllServices.Delete.Resp>
  delete(
    url: typeof APITYPES.Employees.Delete.URL,
    options: { replaceUrl: { carwash_id: string, employee_id: string } }
  ): Promise<APITYPES.Employees.Delete.Resp>
  delete(
    url: typeof APITYPES.AllCarClasses.DeleteCar.URL,
    options: { replaceUrl: { car_class_id: string, car_model_id: string } }
  ): Promise<APITYPES.AllCarClasses.DeleteCar.Resp>
  delete(
    url: typeof APITYPES.CarClasses.Delete.URL,
    options: { replaceUrl: { client_type_id: string, car_class_id: string } }
  ): Promise<APITYPES.CarClasses.Delete.Resp>
  delete(
    url: typeof APITYPES.Services.Delete.URL,
    options: { replaceUrl: { client_type_id: string, service_id: string } }
  ): Promise<APITYPES.Services.Delete.Resp>
  delete(
    url: typeof APITYPES.ClientTypes.Delete.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.ClientTypes.Delete.Resp>
  delete(
    url: typeof APITYPES.File.Delete.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.File.Delete.Resp>

  //monitoring
  get(
    url: typeof APITYPES.DoerMonitoring.GetAllocations.URL,
    options: { params: APITYPES.DoerMonitoring.GetAllocations.Req }
  ): Promise<APITYPES.DoerMonitoring.GetAllocations.Resp>

  get(
    url: typeof APITYPES.DoerMonitoring.UpdateAllocations.URL,
    options: { params: APITYPES.DoerMonitoring.UpdateAllocations.Req }
  ): Promise<APITYPES.DoerMonitoring.UpdateAllocations.Resp>

  //schedules
  get(
    url: typeof APITYPES.Schedules.Get.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Schedules.Get.Resp>
  get(
    url: typeof APITYPES.Schedules.GetItem.URL,
    options: { replaceUrl: { carwash_id: string, schedule_id: string } }
  ): Promise<APITYPES.Schedules.GetItem.Resp>
  get(
    url: typeof APITYPES.Schedules.GetIntervals.URL,
    options: { replaceUrl: { carwash_id: string, schedule_id: string } }
  ): Promise<APITYPES.Schedules.GetIntervals.Resp>
  get(
    url: typeof APITYPES.Schedules.GetInterval.URL,
    options: {
      replaceUrl: {
        carwash_id: string
        schedule_id: string
        interval_id: string
      }
    }
  ): Promise<APITYPES.Schedules.GetInterval.Resp>
  delete(
    url: typeof APITYPES.Schedules.Delete.URL,
    options: { replaceUrl: { carwash_id: string, schedule_id: string } }
  ): Promise<APITYPES.Schedules.Delete.Resp>
  delete(
    url: typeof APITYPES.Schedules.DeleteInterval.URL,
    options: {
      replaceUrl: {
        carwash_id: string
        schedule_id: string
        interval_id: string
      }
    }
  ): Promise<APITYPES.Schedules.DeleteInterval.Resp>
  post(
    url: typeof APITYPES.Schedules.CreateInterval.URL,
    data: APITYPES.Schedules.CreateInterval.Req,
    options: { replaceUrl: { carwash_id: string, schedule_id: string } }
  ): Promise<APITYPES.Schedules.CreateInterval.Resp>
  post(
    url: typeof APITYPES.Schedules.Post.URL,
    data: APITYPES.Schedules.Post.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Schedules.Post.Resp>
  put(
    url: typeof APITYPES.Schedules.Put.URL,
    data: APITYPES.Schedules.Put.Req,
    options: { replaceUrl: { carwash_id: string, schedule_id: string } }
  ): Promise<APITYPES.Schedules.Put.Resp>
  put(
    url: typeof APITYPES.Schedules.UpdateInterval.URL,
    data: APITYPES.Schedules.UpdateInterval.Req,
    options: {
      replaceUrl: {
        carwash_id: string
        schedule_id: string
        interval_id: string
      }
    }
  ): Promise<APITYPES.Schedules.UpdateInterval.Resp>

  //penalties
  get(url: typeof APITYPES.Penalty.Get.URL): Promise<APITYPES.Penalty.Get.Resp>
  get(
    url: typeof APITYPES.Carwashes.GetPenalties.URL,
    options: {
      replaceUrl: { carwash_id: string }
      params: APITYPES.Carwashes.GetPenalties.Query
    }
  ): Promise<APITYPES.Carwashes.GetPenalties.Resp>
  post(
    url: typeof APITYPES.Penalty.Post.URL,
    data: APITYPES.Penalty.Post.Req,
    options: { replaceUrl: { carwash_id: string } }
  ): Promise<APITYPES.Penalty.Post.Resp>
  post(
    url: typeof APITYPES.Penalty.UpdateStatus.URL,
    data: APITYPES.Penalty.UpdateStatus.Req,
    options: {
      replaceUrl: {
        carwash_id: string
        carwash_penalty_id: string
        new_status: string
      }
    }
  ): Promise<APITYPES.Penalty.UpdateStatus.Resp>

  //compensations
  get(url: typeof APITYPES.Compensation.Get.URL): Promise<APITYPES.Compensation.Get.Resp>
  get(
    url: typeof APITYPES.Carwashes.GetCompensations.URL,
    options: {
      replaceUrl: { carwash_id: string }
      params: APITYPES.Carwashes.GetCompensations.Query
    }
  ): Promise<APITYPES.Carwashes.GetCompensations.Resp>
  post(
    url: typeof APITYPES.Compensation.Post.URL,
    data: APITYPES.Compensation.Post.Req,
    options: { replaceUrl: { carwash_id: string } }
  ): Promise<APITYPES.Compensation.Post.Resp>

  //doers
  get(
    url: typeof APITYPES.Doers.Get.URL,
    options: { params: APITYPES.Doers.Get.Req }
  ): Promise<APITYPES.Doers.Get.Resp>
  put(
    url: typeof APITYPES.Doers.PutContractorsClearWallet.URL,
    data: APITYPES.Doers.PutContractorsClearWallet.Req,
    options: { replaceUrl: { contractor_id: string } }
  ): Promise<APITYPES.Doers.PutContractorsClearWallet.Resp>
  put(
    url: typeof APITYPES.Doers.Put.URL,
    data: APITYPES.Doers.Put.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Doers.Put.Resp>
  put(
    url: typeof APITYPES.Doers.Block.URL,
    data: APITYPES.Doers.Block.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Doers.Block.Resp>
  put(
    url: typeof APITYPES.Doers.Confirm.URL,
    data: APITYPES.Doers.Confirm.Req,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Doers.Confirm.Resp>
  get(
    url: typeof APITYPES.Doers.Get.URL,
    options: { params: { name: string } }
  ): Promise<APITYPES.Doers.Get.Resp>
  get(
    url: typeof APITYPES.Doers.GetById.URL,
    options: { replaceUrl: { doerId: string } }
  ): Promise<APITYPES.Doers.GetById.Resp>
  get(
    url: typeof APITYPES.Doers.GetShifts.URL,
    options: {
      replaceUrl: { doerId: string }
      params?: APITYPES.Doers.GetShifts.Params
    }
  ): Promise<APITYPES.Doers.GetShifts.Resp>
  get(
    url: typeof APITYPES.Doers.GetReserves.URL,
    options: {
      replaceUrl: { doerId: string }
      params?: APITYPES.Doers.GetReserves.Params
    }
  ): Promise<APITYPES.Doers.GetReserves.Resp>

  post(
    url: typeof APITYPES.Doers.POST.URL,
    body: APITYPES.Doers.POST.Req
  ): Promise<APITYPES.Doers.POST.Resp>

  //roles
  get(
    url: typeof APITYPES.Doers.Roles.GET.URL,
    option: { params: APITYPES.Doers.Roles.GET.Params }
  ): Promise<APITYPES.Doers.Roles.GET.Resp>
  post(
    url: typeof APITYPES.Doers.Roles.POST.URL,
    body: APITYPES.Doers.Roles.POST.Req
  ): Promise<APITYPES.Doers.Roles.POST.Resp>
  put(
    url: typeof APITYPES.Doers.Roles.PUT.URL,
    body: APITYPES.Doers.Roles.PUT.Req,
    options: { replaceUrl: { role_id: string } }
  ): Promise<APITYPES.Doers.Roles.PUT.Resp>

  //doer roles
  get(
    url: typeof APITYPES.Doers.DoerRole.GET.URL,
    options: { replaceUrl: APITYPES.Doers.DoerRole.GET.Params }
  ): Promise<APITYPES.Doers.DoerRole.GET.Resp>
  post(
    url: typeof APITYPES.Doers.DoerRole.POST.URL,
    body: APITYPES.Doers.DoerRole.POST.Req,
    options: { replaceUrl: APITYPES.Doers.DoerRole.POST.Params }
  ): Promise<APITYPES.Doers.DoerRole.POST.Resp>
  delete(
    url: typeof APITYPES.Doers.DoerRole.DELETE.URL,
    options: { replaceUrl: APITYPES.Doers.DoerRole.DELETE.Params }
  ): Promise<APITYPES.Doers.DoerRole.DELETE.Resp>

  //rolegroups
  get(
    url: typeof APITYPES.Doers.RoleGroups.GET.URL,
  ): Promise<APITYPES.Doers.RoleGroups.GET.Resp>

  //shifts
  get(
    url: typeof APITYPES.Shifts.ShiftTypes.GET.URL
  ): Promise<APITYPES.Shifts.ShiftTypes.GET.Resp>
  get(
    url: typeof APITYPES.Shifts.Employer.GET.URL,
    options?: { params?: APITYPES.Shifts.Employer.GET.Query }
  ): Promise<APITYPES.Shifts.Employer.GET.Resp>
  post(
    url: typeof APITYPES.Shifts.ShiftTypes.POST.URL,
    body: APITYPES.Shifts.ShiftTypes.POST.Req
  ): Promise<APITYPES.Shifts.ShiftTypes.POST.Resp>
  delete(
    url: typeof APITYPES.Shifts.ShiftById.DELETE.URL,
    options: { replaceUrl: APITYPES.Shifts.ShiftById.DELETE.Params }
  ): Promise<APITYPES.Shifts.ShiftById.DELETE.Resp>
  post(
    url: typeof APITYPES.Shifts.ShiftById.CANCEL.URL,
    body: APITYPES.Shifts.ShiftById.CANCEL.Req,
    options: { replaceUrl: APITYPES.Shifts.ShiftById.CANCEL.Params }
  ): Promise<APITYPES.Shifts.ShiftById.CANCEL.Resp>
  get(
    url: typeof APITYPES.Shifts.Shift.GET.URL,
    options: { params: APITYPES.ShiftFilter }
  ): Promise<APITYPES.Shifts.Shift.GET.Resp>
  post(
    url: typeof APITYPES.Shifts.Shift.POST.URL,
    body: APITYPES.Shifts.Shift.POST.ReqCreate
  ): Promise<APITYPES.Shifts.Shift.POST.Resp>
  post(
    url: typeof APITYPES.Shifts.Shift.POST.URL,
    body: APITYPES.Shifts.Shift.POST.ReqImport,
  ): Promise<APITYPES.Shifts.Shift.POST.Resp>
  get(
    url: typeof APITYPES.Shifts.ShiftById.GET.URL,
    options: { replaceUrl: APITYPES.Shifts.ShiftById.GET.Params }
  ): Promise<APITYPES.Shifts.ShiftById.GET.Resp>
  put(
    url: typeof APITYPES.Shifts.ShiftById.PUT.URL,
    body: APITYPES.Shifts.ShiftById.PUT.Req,
    options: { replaceUrl: APITYPES.Shifts.ShiftById.PUT.Params }
  ): Promise<APITYPES.Shifts.ShiftById.PUT.Resp>
  get(
    url: typeof APITYPES.Shifts.ShiftTypesByRole.GET.URL,
    options: { replaceUrl: APITYPES.Shifts.ShiftTypesByRole.GET.Params }
  ): Promise<APITYPES.Shifts.ShiftTypesByRole.GET.Resp>
  post(
    url: typeof APITYPES.Shifts.ShiftTypesByRole.POST.URL,
    body: APITYPES.Shifts.ShiftTypesByRole.POST.Req,
    options: { replaceUrl: APITYPES.Shifts.ShiftTypesByRole.POST.Params }
  ): Promise<APITYPES.Shifts.ShiftTypesByRole.POST.Resp>
  get(
    url: typeof APITYPES.Shifts.ShiftLocations.GET.URL,
    options: { params: APITYPES.Shifts.ShiftLocations.GET.Params }
  ): Promise<APITYPES.Shifts.ShiftLocations.GET.Resp>
  post(
    url: typeof APITYPES.Shifts.ShiftLocations.POST.URL,
    body: APITYPES.Shifts.ShiftLocations.POST.Req
  ): Promise<APITYPES.Shifts.ShiftLocations.POST.Resp>
  put(
    url: typeof APITYPES.Shifts.ShiftLocations.PUT.URL,
    body: APITYPES.Shifts.ShiftLocations.PUT.Req,
    options: { replaceUrl: { location_id: string } }
  ): Promise<APITYPES.Shifts.ShiftLocations.PUT.Resp>
  put(
    url: typeof APITYPES.Shifts.DoerShiftLocations.PUT.URL,
    body: APITYPES.Shifts.DoerShiftLocations.PUT.Req,
    options: { replaceUrl: { doer_shift_id: string } }
  ): Promise<APITYPES.Shifts.DoerShiftLocations.PUT.Resp>
  delete(
    url: typeof APITYPES.Shifts.DoerShiftLocations.DELETE.URL,
    options: { replaceUrl: APITYPES.Shifts.DoerShiftLocations.DELETE.Params }
  ): Promise<APITYPES.Shifts.DoerShiftLocations.DELETE.Resp>
  get(
    url: typeof APITYPES.Shifts.Shift.GetDoers.URL,
    options: {
      params: APITYPES.ShiftDoersFilter
      replaceUrl: { shift_id: string }
    }
  ): Promise<APITYPES.Shifts.Shift.GetDoers.Resp>
  post(
    url: typeof APITYPES.Shifts.AddDoerToShift.URL,
    body: APITYPES.Shifts.AddDoerToShift.Req
  ): Promise<APITYPES.Shifts.AddDoerToShift.Resp>
  put(
    url: typeof APITYPES.Shifts.ChangeDoerShiftStatus.URL,
    body: APITYPES.Shifts.ChangeDoerShiftStatus.Req,
    options: { replaceUrl: { doer_shift_id: string, new_status: string } }
  ): Promise<APITYPES.Shifts.ChangeDoerShiftStatus.Resp>
  put(
    url: typeof APITYPES.Shifts.ChangeDoerShift.URL,
    body: APITYPES.Shifts.ChangeDoerShift.Req,
    options: { replaceUrl: { doer_shift_id: string } }
  ): Promise<APITYPES.Shifts.ChangeDoerShift.Resp>
  get(
    ir: typeof APITYPES.Shifts.ShiftLocations.GetChildren.URL,
    options: { replaceUrl: { location_id: string } }
  ): Promise<APITYPES.Shifts.ShiftLocations.GetChildren.Resp>
  put(
    ir: typeof APITYPES.Shifts.ShiftLocations.PutChildren.URL,
    body: APITYPES.Shifts.ShiftLocations.PutChildren.Req,
    options: { replaceUrl: { location_id: string } }
  ): Promise<APITYPES.Shifts.ShiftLocations.PutChildren.Resp>
  delete(
    ir: typeof APITYPES.Shifts.ShiftLocations.DeleteChildren.URL,
    options: {
      replaceUrl: { location_id: string }
      params: APITYPES.Shifts.ShiftLocations.DeleteChildren.Req
    }
  ): Promise<APITYPES.Shifts.ShiftLocations.DeleteChildren.Resp>
  get(
    ir: typeof APITYPES.Shifts.ShiftLocations.GetFiles.URL,
    options: {
      replaceUrl: { location_id: string }
      params: APITYPES.Shifts.ShiftLocations.GetFiles.Query
    }
  ): Promise<APITYPES.Shifts.ShiftLocations.GetFiles.Resp>
  post(
    ir: typeof APITYPES.Shifts.ShiftLocations.PostFile.URL,
    body: APITYPES.Shifts.ShiftLocations.PostFile.Req,
    options: { replaceUrl: { location_id: string } }
  ): Promise<APITYPES.Shifts.ShiftLocations.PostFile.Resp>
  get(
    url: typeof APITYPES.Shifts.ShiftTypesByRoleId.GET.URL,
    options: { replaceUrl: { role_id: string } }
  ): Promise<APITYPES.Shifts.ShiftTypesByRoleId.GET.Resp>
  post(
    url: typeof APITYPES.Shifts.CommitShifts.POST.URL,
    body: APITYPES.Shifts.CommitShifts.POST.Req,
  ): Promise<APITYPES.Shifts.CommitShifts.POST.Resp>

  //offers
  get(
    url: typeof APITYPES.Offers.GET.URL,
    options: { params: APITYPES.Offers.GET.Query }
  ): Promise<APITYPES.Offers.GET.Resp>
  get(
    url: typeof APITYPES.Offers.GetOfferById.URL,
    options: { replaceUrl: { offerId: string } }
  ): Promise<APITYPES.Offers.GetOfferById.Resp>
  get(
    url: typeof APITYPES.Offers.GetReserves.URL,
    options: {
      params: Omit<APITYPES.Offers.GetReserves.Query, 'offer_id'>
      replaceUrl: { offer_id: string }
    }
  ): Promise<APITYPES.Offers.GetReserves.Resp>
  post(
    url: typeof APITYPES.Offers.SetShiftDoer.URL,
    body: APITYPES.Offers.SetShiftDoer.Req
  ): Promise<APITYPES.Offers.SetShiftDoer.Resp>
  put(
    url: typeof APITYPES.Offers.ChangeReserveOffer.URL,
    body: APITYPES.Offers.ChangeReserveOffer.Req,
    options: { replaceUrl: { reserveId: string } }
  ): Promise<APITYPES.Offers.ChangeReserveOffer.Resp>
  get(
    url: typeof APITYPES.Offers.GetShiftOffers.URL,
    options: { replaceUrl: { shift_id: string } }
  ): Promise<APITYPES.Offers.GetShiftOffers.Resp>
  post(
    url: typeof APITYPES.Offers.CancelReserve.URL,
    // eslint-disable-next-line no-empty-pattern
    {},
    options: { replaceUrl: { reserveId: string } }
  ): Promise<APITYPES.Offers.CancelReserve.Resp>

  // report element
  put(
    url: typeof APITYPES.ReportElement.PUT.URL,
    data: APITYPES.ReportElement.PUT.Req,
    options: { replaceUrl: { report_id: string } }
  ): Promise<APITYPES.ReportElement.PUT.Res>

  // operations
  get(
    url: typeof APITYPES.Operations.GET.URL,
    options: { params: APITYPES.Operations.GET.Query }
  ): Promise<APITYPES.Operations.GET.Resp>
  get(
    url: typeof APITYPES.Operations.GetOperationFiles.URL,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.GetOperationFiles.Resp>
  get(
    url: typeof APITYPES.Operations.GetOperation.URL,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.GetOperation.Resp>
  put(
    url: typeof APITYPES.Operations.EditOperation.URL,
    data: APITYPES.Operations.EditOperation.Req,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.EditOperation.Resp>
  delete(
    url: typeof APITYPES.Operations.DeleteOperation.URL,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.DeleteOperation.Resp>
  post(
    url: typeof APITYPES.Operations.ChangeState.URL,
    data: {},
    options: { replaceUrl: { operationId: string, action: string } }
  ): Promise<APITYPES.Operations.ChangeState.Resp>
  get(
    url: typeof APITYPES.Operations.GetOperationTasks.URL,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.GetOperationTasks.Resp>
  post(
    url: typeof APITYPES.Operations.CreateSuboperation.URL,
    data: APITYPES.Operations.CreateSuboperation.Req,
    options: { replaceUrl: { operationId: string } }
  ): Promise<APITYPES.Operations.CreateSuboperation.Resp>
  post(
    url: typeof APITYPES.Operations.CreateOperationOrders.URL,
    data: APITYPES.Operations.CreateOperationOrders.Req
  ): Promise<APITYPES.Operations.CreateOperationOrders.Resp>
  post(
    url: typeof APITYPES.Operations.CloseOperationOrders.URL,
    data: APITYPES.Operations.CloseOperationOrders.Req
  ): Promise<APITYPES.Operations.CloseOperationOrders.Resp>
  get(url: typeof APITYPES.Operations.GetOperationsSyncState.URL): any

  //news
  get(
    url: typeof APITYPES.News.GET.URL,
    options: { params: APITYPES.News.GET.Query }
  ): Promise<APITYPES.News.GET.Resp>
  get(url: typeof APITYPES.Tasks.GET.URL): Promise<APITYPES.Tasks.GET.Resp>
  post(
    url: typeof APITYPES.News.POST.URL,
    body: APITYPES.News.POST.Req
  ): Promise<APITYPES.News.POST.Resp>
  get(
    url: typeof APITYPES.News.SingleNews.GET.URL,
    options: { replaceUrl: { news_id: string } }
  ): Promise<APITYPES.News.SingleNews.GET.Resp>
  delete(
    url: typeof APITYPES.News.SingleNews.DELETE.URL,
    options: { replaceUrl: { news_id: string } }
  ): Promise<APITYPES.News.SingleNews.DELETE.Resp>
  put(
    url: typeof APITYPES.News.SingleNews.PUT.URL,
    body: APITYPES.News.SingleNews.PUT.Req,
    options: { replaceUrl: { news_id: string } }
  ): Promise<APITYPES.News.SingleNews.PUT.Resp>
  delete(
    url: typeof APITYPES.News.DeleteNewsRole.URL,
    options: { replaceUrl: { news_role_id: string } }
  ): Promise<APITYPES.News.DeleteNewsRole.Resp>

  //users
  get(
    url: typeof APITYPES.Users.Get.URL,
    options: { params: APITYPES.Users.Get.Req }
  ): Promise<APITYPES.Users.Get.Resp>
  get(
    url: typeof APITYPES.Users.GetUser.URL,
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Users.GetUser.Resp>
  put(
    url: typeof APITYPES.Users.ChangeCar.URL,
    data: APITYPES.Users.ChangeCar.Req,
    options: { replaceUrl: { user_id: string, car_id: string } }
  ): Promise<APITYPES.Users.ChangeCar.Resp>
  get(
    url: typeof APITYPES.Users.GetSubscriptions.URL,
    options: {
      params: { user_id: string & APITYPES.Users.GetSubscriptions.Query }
    }
  ): Promise<APITYPES.Users.GetSubscriptions.Resp>
  get(
    url: typeof APITYPES.Users.GetOrders.URL,
    options: { params: { user_id: string & APITYPES.Users.GetOrders.Query } }
  ): Promise<APITYPES.Users.GetOrders.Resp>

  // rent
  post(
    url: typeof APITYPES.Rent.POST.URL,
    body: APITYPES.Rent.POST.Req
  ): Promise<APITYPES.Rent.POST.Resp>
  post(
    url: typeof APITYPES.Rent.SendRentsToBilling.URL,
    body: APITYPES.Rent.SendRentsToBilling.Req
  ): Promise<APITYPES.Rent.SendRentsToBilling.Resp>

  // products
  get(
    url: typeof APITYPES.Products.GET.URL,
    options: { params: APITYPES.Products.GET.Req }
  ): Promise<APITYPES.Products.GET.Resp>
  get(
    url: typeof APITYPES.Products.GET.URL,
    options: { params: { type: 'option' } }
  ): Promise<APITYPES.Products.GET.Resp>
  get(
    url: typeof APITYPES.Products.GetById.URL,
    options: { replaceUrl: { productId: string } }
  ): Promise<APITYPES.Products.GetById.Resp>
  post(
    url: typeof APITYPES.Products.POST.URL,
    body: APITYPES.Products.POST.Req
  ): Promise<APITYPES.Products.POST.Resp>
  put(
    url: typeof APITYPES.Products.PUT.URL,
    body: APITYPES.Products.PUT.Req,
    options: { replaceUrl: { productId: string } }
  ): Promise<APITYPES.Products.PUT.Resp>
  post(
    url: typeof APITYPES.Products.SERVICE.POST.URL,
    body: APITYPES.Products.SERVICE.POST.Req,
    options: { replaceUrl: { productId: string } }
  ): Promise<APITYPES.Products.SERVICE.POST.Resp>
  delete(
    url: typeof APITYPES.Products.SERVICE.DELETE.URL,
    options: { replaceUrl: { productId: string, serviceId: string } }
  ): Promise<APITYPES.Products.SERVICE.DELETE.Resp>
  post(
    url: typeof APITYPES.Products.OPTION.POST.URL,
    body: APITYPES.Products.OPTION.POST.Req,
    options: { replaceUrl: { productId: string } }
  ): Promise<APITYPES.Products.OPTION.POST.Resp>
  delete(
    url: typeof APITYPES.Products.OPTION.DELETE.URL,
    options: { replaceUrl: { productId: string, optionProductId: string } }
  ): Promise<APITYPES.Products.OPTION.DELETE.Resp>

  get(
    url: typeof APITYPES.Products.GROUP.GET.URL
  ): Promise<APITYPES.Products.GROUP.GET.Resp>
  post(
    url: typeof APITYPES.Products.GROUP.POST.URL,
    body: APITYPES.Products.GROUP.POST.Req
  ): Promise<APITYPES.Products.GROUP.POST.Resp>
  put(
    url: typeof APITYPES.Products.GROUP.PUT.URL,
    body: APITYPES.Products.GROUP.PUT.Req,
    options: { replaceUrl: { groupId: string } }
  ): Promise<APITYPES.Products.GROUP.PUT.Resp>
  delete(
    url: typeof APITYPES.Products.GROUP.DELETE.URL,
    options: { replaceUrl: { groupId: string } }
  ): Promise<APITYPES.Products.GROUP.DELETE.Resp>

  // customer tariffs
  get(
    url: typeof APITYPES.Tariffs.GET.URL,
    options: { params: APITYPES.Tariffs.GET.Req }
  ): Promise<APITYPES.Tariffs.GET.Resp>
  get(
    url: typeof APITYPES.Tariffs.GetById.URL,
    options: { replaceUrl: { tariffId: string } }
  ): Promise<APITYPES.Tariffs.GetById.Resp>
  post(
    url: typeof APITYPES.Tariffs.POST.URL,
    body: APITYPES.Tariffs.POST.Req
  ): Promise<APITYPES.Tariffs.POST.Resp>
  put(
    url: typeof APITYPES.Tariffs.PUT.URL,
    body: APITYPES.Tariffs.PUT.Req,
    options: { replaceUrl: { customerTariffId: string } }
  ): Promise<APITYPES.Tariffs.PUT.Resp>

  get(
    url: typeof APITYPES.Tariffs.Product.GET.URL,
    options: { replaceUrl: { customerTariffId: string } }
  ): Promise<APITYPES.Tariffs.Product.GET.Resp>
  post(
    url: typeof APITYPES.Tariffs.Product.POST.URL,
    body: APITYPES.Tariffs.Product.POST.Req,
    options: { replaceUrl: { customerTariffId: string } }
  ): Promise<APITYPES.Tariffs.Product.POST.Resp>
  put(
    url: typeof APITYPES.Tariffs.Product.PUT.URL,
    body: APITYPES.Tariffs.Product.PUT.Req,
    options: { replaceUrl: { customerTariffId: string, productId: string } }
  ): Promise<APITYPES.Tariffs.Product.PUT.Resp>
  delete(
    url: typeof APITYPES.Tariffs.Product.DELETE.URL,
    options: { replaceUrl: { customerTariffId: string, productId: string } }
  ): Promise<APITYPES.Tariffs.Product.DELETE.Resp>

  get(
    url: typeof APITYPES.Tariffs.Location.GET.URL,
    options: { replaceUrl: { customerTariffId: string } }
  ): Promise<APITYPES.Tariffs.Location.GET.Resp>
  post(
    url: typeof APITYPES.Tariffs.Location.POST.URL,
    body: APITYPES.Tariffs.Location.POST.Req,
    options: { replaceUrl: { customerTariffId: string } }
  ): Promise<APITYPES.Tariffs.Location.POST.Resp>
  delete(
    url: typeof APITYPES.Tariffs.Location.DELETE.URL,
    options: { replaceUrl: { customerTariffId: string, locationId: string } }
  ): Promise<APITYPES.Tariffs.Location.DELETE.Resp>

  // doer tariffs
  get(
    url: typeof APITYPES.DoerTariff.GET.URL,
    options: { params: APITYPES.DoerTariffFilter }
  ): Promise<APITYPES.DoerTariff.GET.Resp>
  get(
    url: typeof APITYPES.DoerTariff.MotivationStatistics.URL,
    options: { params: APITYPES.DoerTariff.MotivationStatistics.Req }
  ): Promise<APITYPES.DoerTariff.MotivationStatistics.Resp>
  get(
    url: typeof APITYPES.DoerTariff.Groups.GET.URL,
    options: { params: APITYPES.DoerTariff.Groups.GET.Req }
  ): Promise<APITYPES.DoerTariff.Groups.GET.Resp>
  get(
    url: typeof APITYPES.DoerTariff.GetById.URL,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.GetById.Resp>
  post(
    url: typeof APITYPES.DoerTariff.POST.URL,
    body: APITYPES.DoerTariff.POST.Req
  ): Promise<APITYPES.DoerTariff.POST.Resp>
  put(
    url: typeof APITYPES.DoerTariff.PUT.URL,
    body: APITYPES.DoerTariff.PUT.Req,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.PUT.Resp>
  delete(
    url: typeof APITYPES.DoerTariff.DELETE.URL,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.DELETE.Resp>

  post(
    url: typeof APITYPES.DoerTariff.Tasks.POST.URL,
    body: APITYPES.DoerTariff.Tasks.POST.Req,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.Tasks.POST.Resp>
  put(
    url: typeof APITYPES.DoerTariff.Tasks.PUT.URL,
    body: APITYPES.DoerTariff.Tasks.PUT.Req,
    options: { replaceUrl: { taskId: string } }
  ): Promise<APITYPES.DoerTariff.Tasks.PUT.Resp>
  delete(url: typeof APITYPES.DoerTariff.Tasks.DELETE.URL, options: { replaceUrl: { taskId: string } }
  ): Promise<APITYPES.DoerTariff.Tasks.DELETE.Resp>

  get(
    url: typeof APITYPES.DoerTariff.ShiftTypes.GET.URL,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftTypes.GET.Resp>
  post(
    url: typeof APITYPES.DoerTariff.ShiftTypes.POST.URL,
    body: APITYPES.DoerTariff.ShiftTypes.POST.Req,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftTypes.POST.Resp>
  put(
    url: typeof APITYPES.DoerTariff.ShiftTypes.PUT.URL,
    body: APITYPES.DoerTariff.ShiftTypes.PUT.Req,
    options: { replaceUrl: { shiftTypeId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftTypes.PUT.Resp>
  delete(url: typeof APITYPES.DoerTariff.ShiftTypes.DELETE.URL, options: { replaceUrl: { shiftTypeId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftTypes.DELETE.Resp>

  post(
    url: typeof APITYPES.DoerTariff.ShiftPrices.POST.URL,
    body: APITYPES.DoerTariff.ShiftPrices.POST.Req,
    options: { replaceUrl: { doerTariffId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftPrices.POST.Resp>
  put(
    url: typeof APITYPES.DoerTariff.ShiftPrices.PUT.URL,
    body: APITYPES.DoerTariff.ShiftPrices.PUT.Req,
    options: { replaceUrl: { shiftCostId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftPrices.PUT.Resp>
  delete(url: typeof APITYPES.DoerTariff.ShiftPrices.DELETE.URL, options: { replaceUrl: { shiftCostId: string } }
  ): Promise<APITYPES.DoerTariff.ShiftPrices.DELETE.Resp>

  get(url: typeof APITYPES.DoerTariff.Functions.GET.URL): Promise<APITYPES.DoerTariff.Functions.GET.Resp>
  get(url: typeof APITYPES.DoerTariff.GetTariffSyncStateV2.URL): any
  get(
    url: typeof APITYPES.DoerTariff.GetBillCalculations.URL,
    option: {params: APITYPES.DoerTariff.GetBillCalculations.Params}
  ): Promise<APITYPES.DoerTariff.PostBillCalculations.Resp>
  post(
    url: typeof APITYPES.DoerTariff.PostBillCalculations.URL,
    data: APITYPES.DoerTariff.PostBillCalculations.Req
  ): Promise<APITYPES.DoerTariff.PostBillCalculations.Resp>

  // fleets
  get(url: typeof APITYPES.Fleets.GET.URL): Promise<APITYPES.Fleets.GET.Resp>
  get(url: typeof APITYPES.Fleets.GetCars.URL, options: {params: APITYPES.Fleets.GetCars.Req}): Promise<APITYPES.Fleets.GetCars.Resp>
  get(url: typeof APITYPES.Fleets.GetById.URL, options: {replaceUrl: {fleet_id: string} }): Promise<APITYPES.Fleets.GetById.Resp>
  post(url: typeof APITYPES.Fleets.CreateFleetCar.URL, body: APITYPES.Fleets.CreateFleetCar.Req): Promise<APITYPES.Fleets.CreateFleetCar.Resp>
  put(url: typeof APITYPES.Fleets.ChangeFleetCar.URL, body: APITYPES.Fleets.ChangeFleetCar.Req, options: {replaceUrl: {fleet_id: string}}): Promise<APITYPES.Fleets.ChangeFleetCar.Resp>
  delete(url: typeof APITYPES.Fleets.DeleteFleetCar.URL, options: {replaceUrl: {fleet_id: string}}): Promise<APITYPES.Fleets.DeleteFleetCar.Resp>
  get(url: typeof APITYPES.Fleets.GetServices.URL, options: {params: APITYPES.Fleets.GetServices.Req}): Promise<APITYPES.Fleets.GetServices.Resp>
  post(url: typeof APITYPES.Fleets.CreateFleetServices.URL, body: APITYPES.Fleets.CreateFleetServices.Req): Promise<APITYPES.Fleets.CreateFleetServices.Resp>
  put(url: typeof APITYPES.Fleets.ChangeFleetService.URL, body: APITYPES.Fleets.ChangeFleetService.Req, options: {replaceUrl: {fleet_service_id: string}}): Promise<APITYPES.Fleets.ChangeFleetService.Resp>
  delete(url: typeof APITYPES.Fleets.DeleteFleetService.URL, options: {replaceUrl: {fleet_service_id: string}}): Promise<APITYPES.Fleets.DeleteFleetService.Resp>


  // mailing
  get(url: typeof APITYPES.Mailing.GetList.URL, options?: { params?: APITYPES.Mailing.GetList.Req }): Promise<APITYPES.Mailing.GetList.Resp>
  post(url: typeof APITYPES.Mailing.Post.URL, body: APITYPES.Mailing.Post.Req): Promise<APITYPES.Mailing.Post.Resp>

  //motivations
  get(url: typeof APITYPES.Performers.GetDoerMotivations.URL, options?: { params?: APITYPES.Performers.GetDoerMotivations.Req }): Promise<APITYPES.Performers.GetDoerMotivations.Resp[]>
  get(url: typeof APITYPES.Performers.GetPenalties.URL, options?: { params?: APITYPES.Performers.GetPenalties.Req }): Promise<APITYPES.Performers.GetPenalties.Resp[]>
  get(url: typeof APITYPES.Performers.GetDoerPenalty.URL, options?: { replaceUrl: { penalty_id: string } }): Promise<APITYPES.Performers.GetDoerPenalty.Resp>
  get(url: typeof APITYPES.Performers.GetDoerCompensation.URL, options?: { replaceUrl: { compensation_id: string } }): Promise<APITYPES.Performers.GetDoerCompensation.Resp>
  post(url: typeof APITYPES.Performers.PostPenalty.URL, body: APITYPES.Performers.PostPenalty.Req): Promise<APITYPES.Performers.PostPenalty.Resp>
  post(url: typeof APITYPES.Performers.UpdatePenaltyStatus.URL, body: APITYPES.Performers.UpdatePenaltyStatus.Req, options: { replaceUrl: { penalty_id: string, new_status: string}}): Promise<APITYPES.Performers.UpdatePenaltyStatus.Resp>
  post(url: typeof APITYPES.Performers.PostCompensation.URL, body: APITYPES.Performers.PostCompensation.Req): Promise<APITYPES.Performers.PostCompensation.Resp>
  post(url: typeof APITYPES.Performers.UpdateCompensationStatus.URL, body: APITYPES.Performers.UpdateCompensationStatus.Req, options: { replaceUrl: { compensation_id: string, new_status: string}}): Promise<APITYPES.Performers.UpdateCompensationStatus.Resp>

  // vacancies
  get(
    url: typeof APITYPES.DoerVacancies.Get.URL,
    options?: { params?: APITYPES.DoerVacancies.Get.Query }
  ): Promise<APITYPES.DoerVacancies.Get.Resp>
  post(
    url: typeof APITYPES.DoerVacancies.Post.URL,
    body: APITYPES.DoerVacancies.Post.Req,
  ): Promise<APITYPES.DoerVacancies.Post.Resp>
  get(
    url: typeof APITYPES.DoerVacancies.GetVacancy.URL,
    options: { replaceUrl: APITYPES.DoerVacancies.GetVacancy.Params }
  ): Promise<APITYPES.DoerVacancies.GetVacancy.Resp>
  put(
    url: typeof APITYPES.DoerVacancies.PutVacancy.URL,
    body: APITYPES.DoerVacancies.PutVacancy.Req,
    options: { replaceUrl: APITYPES.DoerVacancies.PutVacancy.Params }
  ): Promise<APITYPES.DoerVacancies.PutVacancy.Resp>
  post(
    url: typeof APITYPES.DoerVacancies.OpenVacancyById.URL,
    body: APITYPES.DoerVacancies.OpenVacancyById.Req,
    options: { replaceUrl: APITYPES.DoerVacancies.OpenVacancyById.Params }
  ): Promise<APITYPES.DoerVacancies.OpenVacancyById.Resp>
  post(
    url: typeof APITYPES.DoerVacancies.CancelVacancyById.URL,
    body: APITYPES.DoerVacancies.CancelVacancyById.Req,
    options: { replaceUrl: APITYPES.DoerVacancies.CancelVacancyById.Params }
  ): Promise<APITYPES.DoerVacancies.CancelVacancyById.Resp>

  // yandexpro
  get(url: typeof APITYPES.YandexPro.Reports.GET.URL): Promise<APITYPES.YandexPro.Reports.GET.Resp>
  post(
    url: typeof APITYPES.YandexPro.Reports.POST.URL,
    body: APITYPES.YandexPro.Reports.POST.ReqCreate
  ): Promise<APITYPES.YandexPro.Reports.POST.Resp>
  get(
    url: typeof APITYPES.YandexPro.Reports.GetReportById.URL,
    options: { replaceUrl: { report_id: string } }
  ): Promise<APITYPES.YandexPro.Reports.GetReportById.Resp>
  post(
    url: typeof APITYPES.YandexPro.Reports.DeployReport.URL,
    body: {},
    options: { replaceUrl: { report_id: string } }
  ): Promise<APITYPES.YandexPro.Reports.DeployReport.Resp>
  get(url: typeof APITYPES.YandexPro.GETExecutors.URL,
    options: { params: APITYPES.YandexPro.GETExecutors.Params }
  ): Promise<APITYPES.YandexPro.GETExecutors.Resp>
  put(url: typeof APITYPES.YandexPro.Reports.Connect.URL, body: APITYPES.YandexPro.Reports.Connect.Req, options: {replaceUrl: {executor_id: string}}): Promise<APITYPES.YandexPro.Reports.Connect.Resp>
  delete(url: typeof APITYPES.YandexPro.Reports.Delete.URL, options: {replaceUrl: {executor_id: string}}): Promise<APITYPES.YandexPro.Reports.Delete.Resp>
  get(url: typeof APITYPES.YandexPro.PlacementsInitial.GET.URL,  options: {params: {location_id: string}}): Promise<APITYPES.YandexPro.PlacementsInitial.GET.Resp>
  post(url: typeof APITYPES.YandexPro.PlacementsInitial.POST.URL,  body: APITYPES.YandexPro.PlacementsInitial.POST.Req): Promise<APITYPES.YandexPro.PlacementsInitial.POST.Resp>
  delete(url: typeof APITYPES.YandexPro.DeleteExecutor.URL,
    options: { replaceUrl: { id: string },  body: {doer_user_id: string} }
  ): Promise<APITYPES.YandexPro.DeleteExecutor.Resp>

  //yandexLavka
  get(url: typeof APITYPES.YandexLavka.Reports.GET.URL): Promise<APITYPES.YandexLavka.Reports.GET.Resp>
  get(url: typeof APITYPES.YandexLavka.Reports.GetById.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.YandexLavka.Reports.GetById.Resp>
  put(url: typeof APITYPES.YandexLavka.UnmatchedExecutors.PUT.URL,body: {doer_user_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.YandexLavka.UnmatchedExecutors.PUT.Resp>
  put(url: typeof APITYPES.YandexLavka.UnmatchedPlacements.PUT.URL,body: {location_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.YandexLavka.UnmatchedPlacements.PUT.Resp>
  delete(url: typeof APITYPES.YandexLavka.UnmatchedPlacements.DELETE.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.YandexLavka.UnmatchedPlacements.DELETE.Resp>
  post(
    url: typeof APITYPES.YandexLavka.DeployReport.URL,
    body: {},
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.YandexLavka.DeployReport.Resp>
  get(url: typeof APITYPES.YandexLavka.Placements.GET.URL,  options: {params: {location_id: string}}): Promise<APITYPES.YandexLavka.Placements.GET.Resp>
  post(url: typeof APITYPES.YandexLavka.Placements.POST.URL,  body: APITYPES.YandexLavka.Placements.POST.Req): Promise<APITYPES.YandexLavka.Placements.POST.Resp>


  //urent
  get(url: typeof APITYPES.Urent.Reports.GET.URL): Promise<APITYPES.Urent.Reports.GET.Resp>
  get(url: typeof APITYPES.Urent.Reports.GetById.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.Urent.Reports.GetById.Resp>
  post(url: typeof APITYPES.Urent.Reports.POST.URL, body: APITYPES.Urent.Reports.POST.Req, options: {headers: {'Content-Type': string}}): Promise<APITYPES.Urent.Reports.POST.Resp>
  put(url: typeof APITYPES.Urent.UnmatchedExecutors.PUT.URL, body: {doer_user_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.Urent.UnmatchedExecutors.PUT.Resp>
  put(url: typeof APITYPES.Urent.UnmatchedPlacements.PUT.URL, body: {location_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.Urent.UnmatchedPlacements.PUT.Resp>
  delete(url: typeof APITYPES.Urent.Placements.DELETE.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.Urent.Placements.DELETE.Resp>
  post(
    url: typeof APITYPES.Urent.DeployReport.URL,
    body: {},
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Urent.DeployReport.Resp>
  get(url: typeof APITYPES.Urent.Placements.GET.URL, options: {params: {location_id: string}}): Promise<APITYPES.Urent.Placements.GET.Resp>
  post(url: typeof APITYPES.Urent.Placements.POST.URL, body: APITYPES.Urent.Placements.POST.Req): Promise<APITYPES.Urent.Placements.POST.Resp>
  get(url: typeof APITYPES.Urent.Executors.GET.URL,
    options: { params: APITYPES.Urent.Executors.GET.Params }
  ): Promise<APITYPES.Urent.Executors.GET.Resp>
  delete(url: typeof APITYPES.Urent.Executors.DELETE.URL,
    options: { replaceUrl: { id: string },  body: {doer_user_id: string} }
  ): Promise<APITYPES.Urent.Executors.DELETE.Resp>

  // magnit
  get(url: typeof APITYPES.Magnit.Reports.GET.URL): Promise<APITYPES.Magnit.Reports.GET.Resp>
  get(url: typeof APITYPES.Magnit.Reports.GetById.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.Magnit.Reports.GetById.Resp>
  post(url: typeof APITYPES.Magnit.Reports.POST.URL, body: APITYPES.Magnit.Reports.POST.Req, options: {headers: {'Content-Type': string}}): Promise<APITYPES.Magnit.Reports.POST.Resp>
  put(url: typeof APITYPES.Magnit.UnmatchedExecutors.PUT.URL, body: {doer_user_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.Magnit.UnmatchedExecutors.PUT.Resp>
  put(url: typeof APITYPES.Magnit.UnmatchedPlacements.PUT.URL, body: {location_id: string}, options: {replaceUrl: {id: string}}): Promise<APITYPES.Magnit.UnmatchedPlacements.PUT.Resp>
  delete(url: typeof APITYPES.Magnit.Placements.DELETE.URL, options: {replaceUrl: {id: string}}): Promise<APITYPES.Magnit.Placements.DELETE.Resp>
  post(
    url: typeof APITYPES.Magnit.DeployReport.URL,
    body: {},
    options: { replaceUrl: { id: string } }
  ): Promise<APITYPES.Magnit.DeployReport.Resp>
  get(url: typeof APITYPES.Magnit.Placements.GET.URL, options: {params: {location_id: string}}): Promise<APITYPES.Magnit.Placements.GET.Resp>
  post(url: typeof APITYPES.Magnit.Placements.POST.URL, body: APITYPES.Magnit.Placements.POST.Req): Promise<APITYPES.Magnit.Placements.POST.Resp>
  get(url: typeof APITYPES.Magnit.Executors.GET.URL,
    options: { params: APITYPES.Magnit.Executors.GET.Params }
  ): Promise<APITYPES.Magnit.Executors.GET.Resp>
  delete(url: typeof APITYPES.Magnit.Executors.DELETE.URL,
    options: { replaceUrl: { id: string },  body: {doer_user_id: string} }
  ): Promise<APITYPES.Magnit.Executors.DELETE.Resp>


  //internships
  get(url: typeof APITYPES.Internships.GET.URL, options: {params: APITYPES.Internships.GET.Req, needFullAxiosResp: boolean}): Promise<AxiosResponse<APITYPES.Internships.GET.Resp>>
  post(url: typeof APITYPES.Internships.POST.URL, body: APITYPES.Internships.POST.Req): Promise<APITYPES.Internships.POST.Resp>
  post(url: typeof APITYPES.Internships.CANCEL.URL, body: APITYPES.Internships.CANCEL.Req,  options: {replaceUrl: APITYPES.Internships.CANCEL.Params}): Promise<APITYPES.Internships.CANCEL.Resp>
  put(url: typeof APITYPES.Internships.PUTPlaces.URL, body: APITYPES.Internships.PUTPlaces.Req,  options: {replaceUrl: APITYPES.Internships.PUTPlaces.Params}): Promise<APITYPES.Internships.PUTPlaces.Resp>

  //reserves
  post(url: typeof APITYPES.Reserves.POST.URL, body: APITYPES.Reserves.POST.Req): Promise<APITYPES.Reserves.POST.Resp>

  //signers
  get(url: typeof APITYPES.Dictionary.Signers.URL): Promise<APITYPES.Dictionary.Signers.Resp>
};

export const toQS = (d: any) =>
  Object.keys(d)
    .map((k) => `${k}=${encodeURIComponent(d[k])}`)
    .join('&')

export const apiV1 = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? API_URL : '/',
  headers: { 'Content-Type': 'application/json' },
})
