import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
} from 'antd'
import { useMutation } from '@tanstack/react-query'

import { required } from 'utils/formUtils'
import { connectAmoCrmLeadAndDoer } from 'AC/yandexpro/reports'
import { APITYPES } from 'types/apitypes'
import { showSuccessNotification } from '../../../../../../utils/notification'

import styles from './styles.module.sass'


type Props = {
  doer: APITYPES.Doer
};

type FormData = APITYPES.Doers.AmoCrmConnection.ConnectAmoCrmExecutorAndDoer.Params;

const YandexPro = ({ doer }: Props) => {
  const [form] = Form.useForm<FormData>()
  const {
    isLoading,
    mutate: connect,
  } = useMutation((data: FormData) =>
    connectAmoCrmLeadAndDoer(doer.id, data),
  {
    onSuccess: () => {
      showSuccessNotification()
      form.resetFields()
    },
  })

  return (
    <Row>
      <Col
        span={12}
        xl={6}
      >
        <Card
          title='Привязка к ID исполнителя в AmoCRM '
          size='small'
        >
          <Form
            form={form}
            onFinish={(data) => connect(data)}
            className={styles.form}
          >
            <Form.Item
              label='ID'
              name='amo_lead_id'
              rules={required}
              className={styles.formItem}
            >
              <Input />
            </Form.Item>
            <Button
              loading={isLoading}
              type='primary'
              htmlType='submit'
            >
              Связать
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default YandexPro
