import { isArray } from 'lodash'
import { RcFile } from 'antd/es/upload'

import {
  action,
  Actions,
  ThAction,
} from 'actions'
import { changeRequestState } from '../request'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { showError } from 'components/modals/ErrorModal'
import { successNotification } from 'components/modals/OperationNotification'
import { apiV1 } from '../../network/http'
import { showInfo } from '../../components/modals/InfoModal'
import { api } from '../../index'


export const getYandexProReports = (): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getYandexProReports', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.YandexPro.Reports.GET.URL)

      if (resp) {
        dispatch(action(Actions.SET_YANDEXPRO_REPORTS, resp))

        dispatch(changeRequestState('getYandexProReports', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getYandexProReports', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const addYandexProReports = (body: APITYPES.YandexPro.Reports.POST.ReqCreate): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('addYandexProReports', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.YandexPro.Reports.POST.URL, body)

      if (resp) {
        await dispatch(getYandexProReports())
        dispatch(changeRequestState('addYandexProReports', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addYandexProReports', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const getYandexProReportById = (report_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getYandexProReport', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.YandexPro.Reports.GetReportById.URL, { replaceUrl: { report_id } })

      if (resp) {
        dispatch(action(Actions.SET_YANDEXPRO_REPORT, resp))

        dispatch(changeRequestState('getYandexProReport', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getYandexProReport', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deployYandexProReportById = (report_id: string, onSuccess?: () => void, onError?: () => void): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deployYandexProReport', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.YandexPro.Reports.DeployReport.URL,{}, { replaceUrl: { report_id } })

      if (resp) {
        dispatch(action(Actions.DEPLOY_YANDEXPRO_REPORT, resp))

        onSuccess && onSuccess()
        showInfo('Отчет обработан')
        dispatch(changeRequestState('deployYandexProReport', StatusRequest.LOADED))

        dispatch(getYandexProReports())
      }
    } catch (e: any) {
      dispatch(changeRequestState('deployYandexProReport', StatusRequest.ERROR))

      onError && onError()

      showError(e.response.data)

      throw(e)
    }
  }
}

export const connectYandexProExecutorAndDoer = (executor_id: string,
  body: APITYPES.YandexPro.Reports.Connect.Req, onSuccess?: Function): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('connectYandexProExecutorAndDoer', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.YandexPro.Reports.Connect.URL,
        body, { replaceUrl: { executor_id } })

      if (resp) {
        dispatch(action(Actions.CONNECT_YANDEXPRO_EXECUTOR_AND_DOER, resp))
        onSuccess && onSuccess()

        successNotification('Связь добавлена')

        dispatch(changeRequestState('connectYandexProExecutorAndDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('connectYandexProExecutorAndDoer', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}


export const getYandexPROExecutors = (filter: APITYPES.YandexPro.GETExecutors.Params): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('getYandexPROExecutors', StatusRequest.LOADING))


    try {
      const resp = await api.get(APITYPES.YandexPro.GETExecutors.URL,
        { params: { doer_user_id: filter.doer_user_id } }
      )

      if (resp) {
        dispatch(action(Actions.SET_YANDEXPRO_EXECUTOR, resp))

        dispatch(changeRequestState('getYandexPROExecutors', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getYandexPROExecutors', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const updateYandexProExecutorAndDoer = (executor_id: string, body: {doer_user_id: string}): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updateYandexProExecutorAndDoer', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.YandexPro.Reports.PUT.URL, body,{ replaceUrl: { executor_id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_YANDEXPRO_EXECUTOR_AND_DOER, resp))

        dispatch(changeRequestState('updateYandexProExecutorAndDoer', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updateYandexProExecutorAndDoer', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const deleteYandexProExecutor = (executor_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('deleteYandexProExecutor', StatusRequest.LOADING))

    try {
      const resp = await api.delete(APITYPES.YandexPro.Reports.Delete.URL, { replaceUrl: { executor_id } })

      if (resp) {
        dispatch(changeRequestState('deleteYandexProExecutor', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('deleteYandexProExecutor', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const addReportFile = (file: RcFile): ThAction => {
  return async (dispatch) => {
    try {
      //TODO: Type URL
      const response = await apiV1.post(
        '/v1/yandexpro/reports',
        file,
        {
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        dispatch(getYandexProReportById(response.data.id))
        if (isArray(response.data?.unmatched_executors)) {
          dispatch(action(Actions.SET_YANDEXPRO_EXECUTOR, response.data.unmatched_executors))
        }
        successNotification('Файл успешно загружен')
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}

export const updateReportFile = (file: any, onSuccess?: () => void): ThAction => {
  return async (dispatch, getState) => {
    const { report } = getState().yandexProReducer

    try {
      //TODO: Type URL
      const response = await apiV1.put(
        `/v1/yandexpro/reports/${report?.id}`,
        file,
        {
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN'),
          },
        }
      )

      if (response) {
        if (onSuccess) {
          onSuccess()
        }
        if (response.data?.unmatched_locations) {
          dispatch(action(Actions.SET_YANDEX_PLACEMENTS, response.data.unmatched_locations))
        }
        successNotification('Файл успешно загружен')
      }
    } catch (e: any) {
      showError(e.response.data)
    }
  }
}


export const deleteExecutor = (executorId: string, doerId: string): ThAction => {
  return async (dispatch, _, { api }) => {
    try {
      const resp = await api.delete(APITYPES.YandexPro.DeleteExecutor.URL,
        {
          replaceUrl: { id: executorId },
          body: { doer_user_id: doerId },
        }
      )

      if (resp) {
        dispatch(action(Actions.DELETE_YANDEX_EXECUTOR, { id: executorId }))
      }
    } catch (e: any) {
      showError(e.response.data)

      throw(e)
    }
  }
}

export const connectAmoCrmLeadAndDoer = async (
  user_id: string,
  data: APITYPES.Doers.AmoCrmConnection.ConnectAmoCrmExecutorAndDoer.Params,
) => {
  try {
    return await api.post(
      APITYPES.Doers.AmoCrmConnection.ConnectAmoCrmExecutorAndDoer.URL,
      data,
      { replaceUrl: { user_id } }
    ) as void
  } catch (e: any) {
    showError(e.response.data.description)
    throw(e)
  }
}
